import React from 'react'
import styles from './sixthBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import CustomPanel from '../customPanel/customPanel'

interface ISixthBlockProps {
  reference: React.RefObject<HTMLDivElement>
  referenceL: React.RefObject<HTMLDivElement>
}

const SixthBlock = (props: ISixthBlockProps) => {
  return (
    <div className={styles.sixthPage} ref={props.reference}>
      <div className={styles.title}>Программа курса</div>
      <div className={styles.panelsWrapper}>
        <CustomPanel
          title={'Вводная лекция'}
          lectures={1}
          hours={1}
          hidenText={
            'Кто такой системный аналитик, его место в команде разработки. Цели и задачи системного аналитика. Отличие системного аналитика от бизнес аналитика.'
          }
        />
        <CustomPanel
          title={'Модели разработки ПО'}
          lectures={1}
          hours={1.5}
          hidenText={
            'Что такое жизненный цикл ПО. Этапы жизненного цикла ПО. Модель жизненного цикла ПО. Виды моделей жизненного цикла ПО: водопадная, спиральная, V-образная'
          }
        />
        <CustomPanel
          title={'Методологии разработки ПО'}
          lectures={1}
          hours={3}
          hidenText={'RUP (Rational Unified Process). Гибкие методологи(Agile,Scrum,Kanban).'}
        />
        <CustomPanel
          title={'Требования к разработке'}
          lectures={1}
          hours={3}
          hidenText={
            'Виды требований. Анализ и сбор требований. Фиксация и изменение требований. Стандартизация требований. Виды проектных документаций.'
          }
        />
        <CustomPanel
          title={'Документирование требований'}
          lectures={1}
          hours={1.5}
          practice={1}
          hidenText={'Что такое ТЗ, его назначение, структура.'}
        />
        <CustomPanel
          title={'UML моделирование'}
          lectures={2}
          hours={3}
          practice={1}
          hidenText={'Что такое моделирование процессов, виды UML диаграмм'}
        />
        <CustomPanel
          title={'Базы данных'}
          lectures={2}
          hours={3}
          practice={1}
          hidenText={
            'Что такое БД, СУБД. Реляционные базы данных. Основные понятия баз данных. Нормализация баз данных. Основы SQL.'
          }
        />
        <CustomPanel
          title={'Интеграционное взаимодействие'}
          lectures={3}
          hours={4.5}
          practice={1}
          hidenText={
            'Что такое интеграция. Синхронный и асинхронный обмен данными. SOAP. WSDL, XML, XSD, REST API. Брокеры сообщений, очереди, FTP.'
          }
        />
        <CustomPanel
          title={'Вспомогательные продукты для работы системного аналитика'}
          lectures={2}
          hours={1.5}
          practice={1}
          hidenText={'Работа с Confluence, Jira, TFS, Redmine, Trello'}
        />
      </div>
      <div className={styles.titleReaders} ref={props.referenceL}>
        Преподаватели
      </div>
      <div className={styles.readersWrapper}>
        <div className={styles.reader}>
          <div className={styles.photo}>
            <StaticImage src='./photo.svg' alt='photo' />
          </div>
          <div className={styles.name}>Дарья Чернышева</div>
          <div className={styles.info}>Ведущий системный аналитик в ГК «Технологии Надёжности»</div>
          <div className={styles.author}>Автор курса «Системный аналитик»</div>
        </div>
        <div className={styles.reader}>
          <div className={styles.photo}>
            <StaticImage src='./photo.svg' alt='photo' />
          </div>
          <div className={styles.name}>Елена Гусева</div>
          <div className={styles.info}>Ведущий системный аналитик в ГК «Технологии Надёжности»</div>
          <div className={styles.author}>Автор курса «Системный аналитик»</div>
        </div>
      </div>
    </div>
  )
}

export default SixthBlock
