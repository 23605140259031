import React, { useState } from 'react'
import styles from './customPanel.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const CustomPanel = (props: {
  title: string
  hidenText: string
  lectures: number
  hours: number
  practice?: number
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  // TODO Можно доделать для всех чисел + практики
  const getStringHours = (hours: number): string => (hours > 1 ? (hours < 5 ? 'часа' : 'часов') : 'час')
  const getStringLectures = (lectures: number): string =>
    lectures > 1 ? (lectures < 5 ? 'лекции' : 'лекций') : 'лекция'

  return (
    <div className={styles.customPanel}>
      <div className={styles.content}>
        <div className={styles.headWrapper}>
          <div className={styles.title}>{props.title}</div>

          <div className={isOpen ? styles.button_active : styles.button} onClick={() => setOpen(!isOpen)}>
            <StaticImage src={'./plusCustomPanel.svg'} alt={'plusCustomPanel'} />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.lectures}>{props.lectures + ' ' + getStringLectures(props.lectures)}</div>
            <hr className={styles.line} />
            <div className={styles.hours}>
              {props.hours.toString().replace('.', ',') + ' ' + getStringHours(props.hours) + ' теории'}
            </div>
            {props.practice && (
              <>
                <hr className={styles.line} />
                <div className={styles.practice}>{props.practice} практика</div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={isOpen ? styles.hiddenTextOpen : styles.hiddenText}>
        <div>{props.hidenText}</div>
      </div>

      <hr className={styles.line} />
    </div>
  )
}

export default CustomPanel
