import React from 'react'
import styles from './fourthBlock.module.scss'

const FourthBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className={styles.fourthPage} ref={props.reference}>
      <div className={styles.title}>Чему вы научитесь</div>
      <div className={styles.boxWrapper}>
        <div className={styles.box}>
          <div>Документировать требования, составлять техническое задание</div>
        </div>
        <div className={styles.box}>
          <div>Моделировать процессы с помощью UML</div>
        </div>
        <div className={styles.box}>
          <div>Строить запросы SQL</div>
        </div>
        <div className={styles.box}>
          <div>Описывать интеграцию систем с помощью REST и SOAP</div>
        </div>
      </div>
    </div>
  )
}

export default FourthBlock
