import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import FirstBlock from '../components/sys-analyst/first-block/firstBlock'
import SecondBlock from '../components/sys-analyst/secondBlock/secondBlock'
import ThirdBlock from '../components/sys-analyst/thirdBlock/thirdBlock'
import FourthBlock from '../components/sys-analyst/fourthBlock/fourthBlock'
import FifthBlock from '../components/sys-analyst/fifthBlock/fifthBlock'
import SixthBlock from '../components/sys-analyst/sixthBlock/sixthBlock'
import SeventhBlock from '../components/sys-analyst/seventhBlock/seventhBlock'
import NinthBlock from '../components/sys-analyst/ninthBlock/ninthBlock'
import EighthBlock from '../components/sys-analyst/eighthBlock/eighthBlock'
import { useEffect, useRef } from 'react'

import * as poly from 'smoothscroll-polyfill'

const SystemsAnalystPage = () => {
  const programRef = useRef<HTMLDivElement>(null)
  const signRef = useRef<HTMLDivElement>(null)

  const aboutCours = useRef<HTMLDivElement>(null)
  const toWho = useRef<HTMLDivElement>(null)
  const forWhat = useRef<HTMLDivElement>(null)
  const howLearn = useRef<HTMLDivElement>(null)
  const lectors = useRef<HTMLDivElement>(null)
  const scrollToRef = (ref: React.RefObject<any>) => ref.current.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => poly.polyfill(), [])

  return (
    <Layout>
      <FirstBlock
        refs={[aboutCours, toWho, forWhat, howLearn, programRef, lectors]}
        titles={[
          'О курсе',
          'Кому подойдет курс',
          'Чему вы научитесь?',
          'Как проходит обучение',
          'Программа курса',
          'Преподаватели',
        ]}
        scrollToProgram={() => scrollToRef(programRef)}
        scrollToSign={() => scrollToRef(signRef)}
      />
      <SecondBlock reference={aboutCours} />
      <ThirdBlock reference={toWho} />
      <FourthBlock reference={forWhat} />
      <FifthBlock reference={howLearn} />
      <SixthBlock reference={programRef} referenceL={lectors} />
      <SeventhBlock reference={signRef} />
      <EighthBlock />
      <NinthBlock />
      <Seo title='Системный аналитик' />
    </Layout>
  )
}

export default SystemsAnalystPage
