import React, { useEffect } from 'react'
import styles from './fifthBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const FifthBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Я знаю, это кринж скорее всего, другого я не придумал
  const handleScroll = () => {
    document.querySelectorAll('#number').forEach((number) => {
      if (window.scrollY + window.pageYOffset * 0.1 > number.offsetParent?.offsetParent?.offsetTop + number.offsetTop) {
        number.classList.add('activeNumber')
      } else {
        number.classList.remove('activeNumber')
      }
    })
  }

  return (
    <div className={styles.fifthPage} ref={props.reference}>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.title}>Как проходит обучение</div>
          <div className={styles.description}>
            Наша цель — сделать из вас профессионалов, которые готовы к любым вызовам, критически мыслят и качественно
            выполняют свою работу. Остальное не заставит себя ждать!
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.task}>
            <div className={styles.numberWrapper}>
              <div className={styles.number} id={'number'}>
                1
              </div>
              <div className={styles.line} id={'number'} />
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.title}>Изучаете тему</div>
              <div className={styles.description}>
                Обучение происходит в online формате. Вы получаете неограниченный доступ к лекциям и вебинарам.
              </div>
              <hr className={styles.line} />
            </div>
          </div>
          <div className={styles.task}>
            <div className={styles.numberWrapper}>
              <div className={styles.number} id={'number'}>
                2
              </div>
              <div className={styles.line} id={'number'} />
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.title}>Выполняете задания</div>
              <div className={styles.description}>
                В конце каждой лекции есть практические занятия, которые помогут закрепить изученный материал.
              </div>
              <hr className={styles.line} />
            </div>
          </div>
          <div className={styles.task}>
            <div className={styles.numberWrapper}>
              <div className={styles.number} id={'number'}>
                3
              </div>
              <div className={styles.line} id={'number'} />
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.title}>Работаете с преподавателем</div>
              <div className={styles.description}>
                Все выполненные работы проверяют наши преподаватели. Они помогут разобрать материал и исправить ошибки.
              </div>
              <hr className={styles.line} />
            </div>
          </div>
          <div className={styles.task}>
            <div className={styles.numberWrapper}>
              <div className={styles.number} id={'number'}>
                4
              </div>
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.title}>Защищаете дипломную работу</div>
              <div className={styles.description}>
                В конце обучения каждому студенту предлагается выполнить экзаменационную работу, по результатам которой
                будет выдан сертификат о прохождении курса. Самым успешным студентам будет предложена стажировка на
                одном из проектов.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.circleAnimationBlock}>
        <div className={styles.fifthPageCircle}>
          <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
        </div>
        <div className={styles.fifthPageCircle2}>
          <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
        </div>
      </div>
    </div>
  )
}

export default FifthBlock
