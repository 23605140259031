import React from 'react'
import styles from './eighthBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

import card81 from './card8_1.svg'
import card82 from './card8_2.svg'
import card83 from './card8_3.svg'

const EighthBlock = () => {
  return (
    <div className={styles.eighthBlock}>
      <div className={styles.title}>Смотрите также</div>
      <div className={styles.cardsWrapper}>
        <div
          className={styles.card}
          style={{
            backgroundImage: `url(${card81})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top left',
          }}
        >
          <div className={styles.main}>
            <div className={styles.cardTitle}>QA-инженер</div>
            <div className={styles.cardDescription}>Вам доступны все лекции, трансляции и материалы курса</div>
          </div>
          <div className={styles.info}>
            <div className={styles.block}>
              <div className={styles.title}>Начало курса</div>
              <div className={styles.subtext}>16 мая 2021</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Формат</div>
              <div className={styles.subtext}>Онлайн</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Стоимость</div>
              <div className={styles.subtext}>59 000 ₽</div>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          style={{
            backgroundImage: `url(${card82})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top left',
          }}
        >
          <div className={styles.main}>
            <div className={styles.cardTitle}>Java-разработчик</div>
            <div className={styles.cardDescription}>
              Вы изучите язык Java, чтобы в командах с другими разработчиками создавать продукты наподобие
              Яндекс.Музыки, Тинькофф-банка, Netflix и Кинопоиска.
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.block}>
              <div className={styles.title}>Начало курса</div>
              <div className={styles.subtext}>16 октября 2021</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Формат</div>
              <div className={styles.subtext}>Онлайн</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Стоимость</div>
              <div className={styles.subtext}>59 000 ₽</div>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          style={{
            backgroundImage: `url(${card83})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '5%',
            backgroundPositionY: 'top',
          }}
        >
          <div className={styles.main}>
            <div className={styles.cardTitle}>Python + Data Science</div>
            <div className={styles.cardDescription}>
              Мы хотим научить вас основным инструментам этой профессии: Python и его библиотекам, в том числе
              Scikit-Learn и XGBoost, Jupyter Notebook, SQL.
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.block}>
              <div className={styles.title}>Начало курса</div>
              <div className={styles.subtext}>16 октября 2021</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Формат</div>
              <div className={styles.subtext}>Онлайн</div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>Стоимость</div>
              <div className={styles.subtext}>59 000 ₽</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EighthBlock
