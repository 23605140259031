import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styles from './secondBlock.module.scss'

const SecondBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className={styles.secondPage} ref={props.reference}>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.title}>Системный аналитик – одна из самых востребованных профессий в сфере IT.</div>
          <div className={styles.description}>
            Результатом работы такого специалиста должны быть однозначно понятные требования, реализация которых
            приведет к созданию продукта, удовлетворяющего нуждам и запросам заказчика. Системный аналитик, по сути, это
            связующее звено между заказчиком, который говорит с точки зрения бизнеса и командой разработки, которая
            говорит с точки зрения IT – технологий.
          </div>
        </div>
        <div className={styles.videoWrapper}>
          <iframe
            src='https://www.youtube.com/embed/dQw4w9WgXcQ'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
      </div>
      <div className={styles.plusWrapper}>
        <div className={styles.plus}>
          <div className={styles.plusIcon}>
            <StaticImage src='./plus.svg' alt='plus' />
          </div>
          <div className={styles.plusInfo}>
            <div className={styles.plusTitle}>
              Длительность <br /> курса
            </div>
            <div className={styles.plusDescription}>
              2 месяца – достаточная продолжительность курса для освоения начальных навыков
            </div>
          </div>
        </div>
        <div className={styles.plus}>
          <div className={styles.plusIcon}>
            <StaticImage src='./plus.svg' alt='plus' />
          </div>
          <div className={styles.plusInfo}>
            <div className={styles.plusTitle}>
              Формат <br /> занятий
            </div>
            <div className={styles.plusDescription}>
              Записанные видеолекции и вебинары позволяют проходить обучение в любое время
            </div>
          </div>
        </div>
        <div className={styles.plus}>
          <div className={styles.plusIcon}>
            <StaticImage src='./plus.svg' alt='plus' />
          </div>
          <div className={styles.plusInfo}>
            <div className={styles.plusTitle}>Сопровождение в течение обучения</div>
            <div className={styles.plusDescription}>Преподаватели всегда находятся на связи с обучающимися</div>
          </div>
        </div>
        <div className={styles.plus}>
          <div className={styles.plusIcon}>
            <StaticImage src='./plus.svg' alt='plus' />
          </div>
          <div className={styles.plusInfo}>
            <div className={styles.plusTitle}>
              Помощь <br /> в трудоустройстве
            </div>
            <div className={styles.plusDescription}>
              По окончании курса предоставляется помощь в составлении резюме и подготовке к собеседованию
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondBlock
