import React, { useEffect, useState } from 'react'
import styles from './firstBlock.module.scss'
import TNLogo from '/src/assets/icons/tn-logo.svg'
import KSLogo from '/src/assets/icons/KSLogo.svg'
import TNName from '/src/assets/icons/tn-name.svg'
import Arrow from '/src/assets/icons/arrow.svg'
import Star from '/src/assets/icons/star.svg'
import TNKSLogo from '/src/assets/icons/KSandTnLogo.svg'
import MenuMobileWhite from '/src/assets/icons/menu-mobile.svg'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ArrowUp from '/src/assets/icons/arrowUp.svg'
import MenuMobile from '../../main-page/menuMobile/menuMobile'
import grid from './grid.svg'

interface IFirstBlockProps {
  scrollToProgram: () => void
  scrollToSign: () => void
  refs: React.RefObject<HTMLDivElement>[]
  titles: string[]
}

const FirstBlock = (props: IFirstBlockProps) => {
  const [showArrow, setShowArrow] = useState(false)
  const [menuMobile, setMenuMobile] = React.useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 800) {
      setShowArrow(true)
    } else {
      setShowArrow(false)
    }
  }

  const menuMobileClose = () => {
    setMenuMobile((prevmenuMobile) => !prevmenuMobile)
  }

  return (
    <>
      <MenuMobile refs={props.refs} titles={props.titles} menuMobile={menuMobile} menuMobileClose={menuMobileClose} />
      <div
        className={styles.firstPage}
        style={{
          backgroundImage: `url(${grid})`,
          backgroundPositionX: '100%',
          backgroundPositionY: '100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {showArrow && (
          <div className={styles.arrowToUp} onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
            <ArrowUp />
          </div>
        )}

        <div className={styles.header}>
          <div className={styles.logos}>
            <div className={styles.TNLogo}>
              <TNLogo />
              <TNName />
            </div>
            <div className={styles.KSLogo}>
              <KSLogo />
            </div>
            <div className={styles.mobileLogo}>
              <TNKSLogo />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.headButton} onClick={() => props.scrollToSign()}>
              Записаться на курс
            </button>
            <div
              className={menuMobile ? styles.checkboxOff : styles.checkbox}
              onClick={() => setMenuMobile(!menuMobile)}
            >
              <MenuMobileWhite />
            </div>
          </div>
        </div>

        <Link className={styles.buttonHome} to={'/'}>
          <Arrow />
          <p>На главную</p>
        </Link>

        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <div className={styles.professionSub}>Профессия</div>
            <div className={styles.professionName}>Системный аналитик</div>
            <div className={styles.professionInfo}>
              Вы освоите системный анализ, чтобы помогать компаниям принимать стратегические решения и увеличивать
              прибыль.
            </div>
            <button className={styles.professionProgramButton} onClick={props.scrollToProgram}>
              Программа курса
            </button>
          </div>
          <div className={styles.listWrapper}>
            <ul>
              <li>
                <Star />
                Online формат
              </li>
              <li>
                <Star />
                15 лекций
              </li>
              <li>
                <Star />5 практических занятий
              </li>
              <li>
                <Star />
                59 000 ₽ стоимость курса
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.circleAnimationBlock}>
          <div className={styles.firstPageCircle}>
            <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
          </div>
          <div className={styles.firstPageCircle2}>
            <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
          </div>
        </div>
      </div>
    </>
  )
}

export default FirstBlock
