import React, { useState } from 'react'
import styles from './seventhBlock.module.scss'
import CustomTextField from '../../main-page/textfield/customTextField'
import axios from 'axios'

interface ISeventhBlockProps {
  reference: React.RefObject<HTMLDivElement>
}

const SeventhBlock = (props: ISeventhBlockProps) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')

  const [valid, setValid] = React.useState<{ name: boolean; phone: boolean; email: boolean }>({
    name: false,
    phone: false,
    email: false,
  })

  const handleChangeValidationName = (payload: boolean) => setValid({ ...valid, name: payload })
  const handleChangeValidationPhone = (payload: boolean) => setValid({ ...valid, phone: payload })
  const handleChangeValidationEmail = (payload: boolean) => setValid({ ...valid, email: payload })

  return (
    <div className={styles.seventhBlock} ref={props.reference}>
      <div className={styles.leftBlock}>
        <div className={styles.title}>Запишись на курс</div>
        <div className={styles.description}>Задайте вопросы по курсам и обучению — мы скоро ответим</div>
      </div>
      <div className={styles.rightBlock}>
        <CustomTextField
          value={name}
          onChangeParent={setName}
          placeholder={'Имя'}
          typeVal={'name'}
          background={'none'}
          border={'1px solid #A1B5BB'}
          color={'rgba(255, 255, 255, 1)'}
          placeholderColor={true}
          handleChangeValidation={handleChangeValidationName}
        />
        <div className={styles.inputsWrapper}>
          <CustomTextField
            value={phone}
            onChangeParent={setPhone}
            placeholder={'Телефон'}
            typeVal={'phone'}
            background={'none'}
            border={'1px solid #A1B5BB'}
            color={'rgba(255, 255, 255, 1)'}
            placeholderColor={'#B2BAF0'}
            handleChangeValidation={handleChangeValidationPhone}
          />
          <CustomTextField
            value={email}
            onChangeParent={setEmail}
            placeholder={'E-mail'}
            typeVal={'email'}
            background={'none'}
            border={'1px solid #A1B5BB'}
            color={'rgba(255, 255, 255, 1)'}
            placeholderColor={'#B2BAF0'}
            handleChangeValidation={handleChangeValidationEmail}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.sendButton}
            disabled={valid.email || valid.name || valid.phone || !phone || !name || !email}
            onClick={() => sendKeker({ name: name, phone: phone, email: email }, 'Системный аналитик')}
          >
            Отправить
          </button>
          <div className={styles.info}>
            Оставляя заявку, вы даете согласие <br />
            на{' '}
            <a className={styles.link} href=''>
              обработку персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

async function sendKeker(form: { name: string; phone: string; email: string }, course: string): Promise<void> {
  console.log(form, course)
  axios
    .post('api/respondings', { course, email: form.email, name: form.name, number: form.phone })
    .then((r) => console.log(r))
    .catch((e) => console.log(e))
}

export default SeventhBlock
