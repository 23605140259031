import React from 'react'
import styles from './thirdBlock.module.scss'

const ThirdBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className={styles.thirdPage} ref={props.reference}>
      <div className={styles.title}>Кому подойдет курс</div>
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Новичкам в IT</div>
          <div className={styles.cardDescription}>
            Изучите основы системного анализа, получите помощь в составлении резюме и подготовке к собеседованию
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Действующим специалистам</div>
          <div className={styles.cardDescription}>
            Структурируете свои знания в области системного анализа для перехода на более высокий уровень
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdBlock
